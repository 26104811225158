import { Checkbox, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import React, { useEffect, useState } from "react";
import { scrollUp } from "../../Layouts/backtotop/Backtotop";
import Custombtn from "../../Layouts/custombtn/Custombtn";
import { Contextdata } from "../../context/Context";
import dayjs from "dayjs";

export default function PatientFinancial() {
  const { patientfinancialchecked, setconsentChecked, personaldetailsvalues } =
    Contextdata();
  useEffect(() => {
    scrollUp();
  }, []);

  const textstyleterms = {
    color: "rgba(4,136,185,1)",
    wordWrap: "break-word",
    cursor: "pointer",
    textDecoration: "underline",
  };

  const [checked, setChecked] = useState(false);

  if (!patientfinancialchecked) {
    window.location.href = "/";
  }
  const handleChecked = (e) => {
    setChecked(e.target.checked);
    setconsentChecked(e.target.checked);
  };

  return (
    <>
      <div className="privacy-container" style={{ margin: "3.3rem 8%" }}>
        <div className="">
          <center>
            <h1>Patient Financial Agreement</h1>
          </center>
          <br />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: "1rem",
            }}
          >
            <b>
              <p>
                Patient Name: {personaldetailsvalues.firstname}{" "}
                {personaldetailsvalues.lastname}
              </p>
            </b>
            <b>
              <p>
                Date of Birth:{" "}
                {new dayjs(personaldetailsvalues.dob)
                  .format("MM/DD/YYYY")
                  .toString()}
              </p>
            </b>
          </Box>
          <br />

          <p>
            Thank you for choosing Orthopaedic Spine Institute (OSI) as your
            health care provider. We are committed to providing patients with
            quality health care. Please read and sign this form to acknowledge
            your understanding of our patient financial policies.
          </p>
          <br />
          <div>
            <strong>Insurance and payment policies:</strong>
            <ul>
              <li>
                The patient (or patient's guardian, if a minor) is ultimately
                responsible for the payment for treatment and care.
              </li>
              <li>
                If you have health insurance, your insurance contract is between
                you and your insurance carrier. It is your responsibility to
                understand your insurance benefits including in-network and
                out-of-network providers, identify any prior authorization
                requirements, and laboratory or hospital preferences. As a
                courtesy, we will bill your insurance if in-network. However,
                you are required to provide the most correct and updated
                information regarding insurance at each visit.
              </li>
              <li>
                All appropriate co-payments and (estimated) coinsurance and/or
                deductibles must be paid at the time of service.
              </li>
              <li>
                You will be responsible for any remaining patient-due cost share
                amount after insurance processes your claim. You will be advised
                and asked to pay any outstanding patient-due balance at the time
                of your visit. We expect prompt payment of the balance owed.
              </li>
            </ul>
          </div>
          <br />
          <div>
            <strong>Self-pay Estimates</strong>
            <ul>
              <li>
                Self-pay and uninsured patients will be provided with a Good
                Faith Estimate for scheduled services. Payment in full is
                required prior to services being rendered.
              </li>
            </ul>
          </div>
          <br />
          <div>
            <strong>No Show and Late Cancellation Fees</strong>
            <ul>
              <li>
                We require a 24-hour notice should you need to cancel or
                reschedule your office appointment. Without such notice, you
                will be charged $100.00 for a missed appointment. These charges
                cannot be billed to your insurance company.
              </li>
              <li>
                If you are more than 15 minutes late without any notification,
                you will not be seen by a provider. The appointment will be
                considered a missed appointment, and the cancellation fee will
                be applied.
              </li>
              <li>
                If you cancel initial consultation appointment more than twice,
                we will no longer schedule you.
              </li>
            </ul>
          </div>
          <br />
          <div>
            <strong>Financial Suspension</strong>
            <ul>
              <li>
                Patients are ultimately responsible for the cost of services
                provided by Orthopaedic Spine Institute (OSI).
              </li>
              <li>
                If a patient's account is more than 90 days past due, a final
                notice will be issued stating that the account must be paid in
                full within 20 business days. Partial payments are not accepted
                unless otherwise negotiated with a payment plan and stored
                credit card on file. If a balance remains unpaid, the account
                may be referred to a collection agency.
              </li>
              <li>
                In the event of nonpayment with the agency the patient and /or
                guarantor shall be responsible either jointly and/or singly for
                all unpaid, past due amounts as well as costs associated with
                collection efforts, including interest accrued on unpaid
                balances, court cost, and reasonable attorneys’ fees.
              </li>
              <p>
                By signing this agreement, I have read and understood the
                Financial Agreement and Responsibilities.
              </p>
              <li>
                I am aware that I may ask for a copy of this Agreement and that
                I may be suspended from receiving care by Orthopaedic Spine
                Institute (OSI) in the event of noncompliance.
              </li>
              <li>
                I am authorizing Orthopaedic Spine Institute (OSI) to charge my
                credit card on file for patient authorized charges for services
                rendered by Orthopaedic Spine Institute (OSI). I understand that
                my information will be saved to my file for future transactions
                on my account.
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* <Box sx={{textAlign: 'center'}} >
          <ColorButton2 onClick={handlesignaturedialogOpen}  >Signature</ColorButton2>
            
          </Box> */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: "3rem",
            marginLeft: { xs: "12px" },
            userSelect: "none",
          }}
        >
          <FormGroup sx={{ textAlign: "left" }}>
            <FormControlLabel
              control={
                <Checkbox checked={checked} onClick={(e) => handleChecked(e)} />
              }
              label="I agree to the Terms and Conditions"
            />
          </FormGroup>
        </Stack>
      </Box>

      <Box sx={{ margin: "0 8%" }}>
        <Custombtn
          linkbck="/privacypractices"
          linknxt="/consenttotreatment"
          checked={checked}
        />
      </Box>
    </>
  );
}
